<template>
  <div class="contentListing" id="content-listing-container">
    <div class="contentListing-contents">
      <div
        class="contentListing-contents-placeholder-container"
        v-if="!episodeList || (episodeList.length == 0 && !noContent && !isLoading)"
      >
        <Loading></Loading>
      </div>

      <div v-else-if="noContent">
        <p class="error-message">{{$t("No Contents Found")}}</p>
      </div>

      <div class="contentListing-contents-card" v-else>
        <div
          class="contentListing-contents-card-item"
          v-for="(item, index) in episodeList"
          :key="index"
          @click="playContent(item, index)"
        >
          <categoryCard
            :class="[
              content.objecttype !== 'CHANEL'
                ? 'contentListing-contents-card-item-design'
                : 'contentListing-contents-card-item-squaredesign',
            ]"
            :displayTags="false"
            :isDirectPlay="false"
            :content="item"
            :cardType="fetchCardType"
          ></categoryCard>

          <div class="contentListing-contents-card-item-container">
            <div class="contentListing-contents-card-item-container-item">
              <div class="contentListing-contents-card-item-container-item-content">
                <p
                  class="contentListing-contents-card-item-container-item-content-tag"
                  :style="[localDisplayLang === 'ara' ? { 'margin-left': '5px' } : { 'margin-right': '5px' }]"
                  v-if="isObjectTag(item) && pickTagForContent(item)"
                >
                 <img src="@/assets/icons/Premium_1_scg.svg" alt="crown">
                </p>
                <p :dir="localDisplayLang === 'ara' ? 'rtl' : ''">{{ item.title }}</p>
              </div>
              <div class="contentListing-contents-card-item-container-item-detail" v-if="!showNumbers">
                <!-- <span>{{showNumbers ? "": item.genre}}</span> -->
                <!-- <span>{{item.contentlanguage[0]}}</span> -->
                <span>{{ item.productionyear }}</span>
                <span>{{ item.pgrating }}</span>
              </div>

              <div
                class="contentListing-contents-card-item-container-item-duration"
                dir="ltr"
                v-if="item.objecttype === 'CONTENT'"
              >
                <img src="@/assets/icons/Duration.svg" alt="items-count" />
                <span :dir="localDisplayLang === 'ara' ? 'rtl' : ''">{{ calcTimeFormat(item.duration) }}</span>
              </div>

              <div
                v-if="item.category === 'TVSHOW' && item.objecttype === 'SERIES'"
                class="contentListing-contents-card-item-container-season"
              >
                <p>
                  {{
                    item.seasoncount > 1
                      ? item.seasoncount + " " + $t("Seasons")
                      : item.seasoncount + " " + $t("Season")
                  }}
                </p>
              </div>
            </div>

            <div class="contentListing-contents-card-item-container-item-description" v-if="item.shortdescription">
              <p>
                {{
                  item.shortdescription.length > 256
                    ? item.shortdescription.slice(0, 256) + "..."
                    : item.shortdescription
                }}
              </p>
            </div>
          </div>
        </div>
      </div>
      <div class="contentListing-contents-placeholder-container" v-if="isLoading == true">
        <Loading></Loading>
      </div>
    </div>
  </div>
</template>

<script>
import { vLivePlaylistItem } from "/src/vue-player/vLiveWebPlayer.js";
import Utility from "@/mixins/Utility.js";
import { mapGetters, mapMutations } from "vuex";
import PlaybackUtilities from "@/mixins/playbackUtilities.js";
import { eventBus } from "@/eventBus";
import { store } from "@/store/store";
import FirebaseActions from "@/mixins/FirebaseActions";

export default {
  props: {
    showNumbers: {
      type: Boolean, // prop for controlling episode or related
    },
    changeStyles: {
      type: Boolean,
    },
    content: {
      type: Object,
    },
    playerInstance: {
      type: Object,
    },
    listType: {
      type: String,
    },
    seasonNum: {
      type: Number,
    },
  },
  data() {
    return {
      availabilities: [],
      subscriptions: [],
      purchases: [],
      filteredAvailabilities: [],
      pricemodel: [],
      localDisplayLang: null,
      playlistIndex: 0,
      isMpegRequired: false,
      playbackInitiated: false,
      episodeList: [],
      totalEpisodeCount: 0,
      isLoading: false,
      noContent: false,
    };
  },
  computed: {
    ...mapGetters(["subscriberid", "availabilityList", "subscriptionList", "purchaseList", "appConfig"]),
    fetchCardType() {
      if (screen.width < 576) {
        return {
          cardName: "Episode",
          type: "LANDSCAPE",
          height: "75px",
          width: "133px",
          //adding quality.
          quality: "THUMBNAIL",
          playButton: { enablePlay: true, height: "20px", width: "20px" },
        };
      } else {
        return this.content.objecttype !== "CHANEL"
          ? {
              cardName: "Trailer",
              type: "LANDSCAPE",
              height: "7rem",
              width: "13rem",
              //adding quality.
              quality: "THUMBNAIL",
              playButton: { enablePlay: true, height: "40px", width: "40px" },
            }
          : {
              cardName: "Slider",
              type: "SQUARE",
              height: "60px",
              playButton: { enablePlay: true },
            };
      }
    },
  },
  watch: {
    seasonNum(val) {
      console.log("Season number : ", val);
      console.log("Season number : ", this.seasonNum);

      if (val > 0) {
        this.episodeList = [];
        this.totalEpisodeCount = 0;
        this.fetchEpisodeList(this.content.objectid, val);
      }
    },
    subscriberid(val) {
      if (val) {
        this.getAvailabilityForAllContents(this.episodeList);
      }
    },
    subscriptionList(val) {
      console.log("subscriber list", val);
      if (val.length > 0) {
        this.subscriptions = val;
        this.getAvailabilityForAllContents(this.episodeList);
      }
    },
    purchaseList(val) {
      if (val) {
        this.purchases = val;
      }
    },
    episodeList(val) {
      if (val) {
        this.getAvailabilityForAllContents(val);
      }
    },
  },
  created() {
    this.isMpegRequired = this.checkMpegRequired();
    this.localDisplayLang = localStorage.getItem("setDisplayLanguageCode");

    console.log("this.appConfig.defaultpageSize", this.appConfig.defaultpageSize);

    //assigning availability list response.
    if (this.availabilityList !== undefined) {
      this.availabilities = this.availabilityList;
    }

    //assigning subscription list response.
    if (this.subscriptionList !== undefined) {
      this.subscriptions = this.subscriptionList;
    }

    if (this.listType == "Episodes") {
      eventBus.$on("send-episode-list", (response) => {
        console.log("Received episode response : ", response);

        if (!response.reason) {
          if (this.totalEpisodeCount == 0) {
            this.totalEpisodeCount = response.totalcount;
          }

          if (this.episodeList.length < this.totalEpisodeCount) {
            if (this.seasonNum == response.data[0].seasonnum) {
              let numOfNewEpisodes = response.data.length;
              this.episodeList = this.episodeList.concat(response.data);

              if (this.playbackInitiated == true) {
                let deltaPlaylist = this.buildPlayerPlaylist(
                  this.episodeList,
                  this.episodeList.length - numOfNewEpisodes
                );

                this.playerInstance.appendPlaylist(deltaPlaylist, this.totalEpisodeCount);
              }

              this.isLoading = false;
            }
          }
        } else {
          this.noContent = true;
          this.episodeList = [];
          this.totalEpisodeCount = 0;
        }
      });
    } else if (this.listType == "Related") {
      eventBus.$on("relatedcontents-response", (response) => {
        console.log("Received related response : ");
        if (!response.reason) {
          if (this.totalEpisodeCount == 0) {
            this.totalEpisodeCount = response.totalcount;
          }

          if (this.episodeList.length < this.totalEpisodeCount) {
            this.episodeList = this.episodeList.concat(response.data);

            // if(this.episodeList.length != this.totalEpisodeCount) {
            //     this.fetchRelatedContents(this.content.objectid);
            // }
            this.isLoading = false;
          } else {
            eventBus.$emit("contentAcquired");
          }
        } else {
          this.noContent = true;
          this.episodeList = [];
        }
      });
    }

    eventBus.$emit("deregister_event");

    if (this.listType == "Episodes") {
      if (this.seasonNum > 0) {
        this.fetchEpisodeList(this.content.objectid, this.seasonNum);
      }
    } else if (this.listType == "Related") {
      this.fetchRelatedContents(this.content.objectid);
    }
  },
  mounted() {
    eventBus.$on("player-visibility", (isVisible) => {
      if (isVisible == false && this.playbackInitiated == true) {
        this.playbackInitiated = false;
        this.playerInstance.deregisterEvent("currentcontentended");
        this.playerInstance.deregisterEvent("loadchosencontent");

        this.playerInstance.deregisterEvent("loadmorecontent");
      }
    });

    let containerElement = document.getElementById("content-listing-container");

    if (containerElement != null) {
      containerElement.addEventListener("scroll", this.handleScrollEvent);
    }
  },
  beforeDestroy() {
    console.log("Destroying content list : ", this.listType);
    if (this.listType == "Episodes") {
      eventBus.$off("send-episode-list");
    } else if (this.listType == "Related") {
      eventBus.$off("relatedcontents-response");
    }
  },
  methods: {
    ...mapMutations(["setRegActiveDeeplink"]),

    handleScrollEvent(event) {
      console.log("THE SCROLL EVENT ------->", event.target.scrollTop);
      if (this.isLoading == false && this.totalEpisodeCount > this.episodeList.length) {
        if (event.target.scrollTop + 600 >= event.target.scrollHeight - event.target.offsetHeight) {
          console.log("SCROLL EVENT TRIGGERED ");

          this.isLoading = true;
          console.log("Load more content");
          if (this.listType == "Related") {
            this.fetchRelatedContents(this.content.objectid);
          } else {
            this.fetchEpisodeList(this.content.objectid, this.seasonNum);
          }
          // EventBus.$emit("loadmorecontent");
        }
      }
    },
    fetchEpisodeList(seriesId, seasonNum) {
      console.log("fetch episode list called from contentListing--------------");
      let pageSize = this.appConfig.defaultpageSize;
      let pageNum = Math.floor(this.episodeList.length / pageSize) + 1;

      let payload = {
        objecttype: "CONTENT",
        seriesid: seriesId,
        seasonnum: seasonNum,
        pagesize: pageSize,
        page: pageNum,
        displaylanguage: this.localDisplayLang,
      };

      if (this.isMpegRequired == true) {
        payload.mpegtspackage = "YES";
      }
      eventBus.$emit("get-episode-list", payload);
    },
    fetchRelatedContents(contentId) {
      let pageSize = 8;
      let pageNum = Math.floor(this.episodeList.length / pageSize) + 1;

      let payload = {
        contentid: contentId,
        params: {
          displaylanguage: this.localDisplayLang,
          pagesize: pageSize,
          page: pageNum,
        },
      };

      if (this.isMpegRequired == true) {
        payload.params.mpegtspackage = "YES";
      }
      eventBus.$emit("getRelatedcontents", payload);
    },
    checkMpegRequired() {
      let isMpegRequired = false;
      let videoTag = document.createElement("video");
      let platformOs = navigator.platform;

      if (videoTag.canPlayType("application/vnd.apple.mpegurl")) {
        if (platformOs.startsWith("Mac")) {
          isMpegRequired = true;
        }
      }

      return isMpegRequired;
    },
    isObjectTag(content) {
      if (content.hasOwnProperty("objecttag")) {
        if (Array.isArray(content.objecttag)) {
          return content.objecttag.length === 0 ? false : true;
        } else {
          return content.objecttag === null ? false : true;
        }
      }
    },
    pickTagForContent(content) {
      if (content.hasOwnProperty("objecttag")) {
        let tag = content.objecttag[0];
        let contenttags = this.appConfig.contentTags;
        if (this.localDisplayLang === "ara") {
          return contenttags.ar[`${tag}`];
        } else {
          return contenttags.en[`${tag}`];
        }
      }
    },
    getAvailabilityForAllContents(val) {
      val.forEach((element) => {
        if (this.subscriberid) {
          this.getAvailability(element);
        }
        element.type = "inner";
      });
    },
    buildContentStreamRequest(contentItem, isMpegRequired) {
      let payload;

      if (contentItem.hasOwnProperty("playback_details")) {
        payload = {
          contentid: contentItem.objectid,
          params: {
            availabilityid: contentItem.playback_details.availabilityid,
            packageid: contentItem.playback_details.packageid,
          },
        };

        if (isMpegRequired == true) {
          payload.params.mpegtspackage = "YES";
        }
      } else {
        this.hidePlayer();

        this.setRegActiveDeeplink(true);

        // Display Subscriptions popup.
        let payload = { listType: "onlySubscribe" };
        eventBus.$emit("subscribePopup", payload);
      }

      return payload;
    },
    fetchContentStreamUrl(contentItem, isMpegRequired) {
      return new Promise((resolve, reject) => {
        if (contentItem.streamUrl != undefined && contentItem.streamUrl != "") {
          resolve(true);
        } else {
          resolve(store.dispatch("contentStreamUrl", this.buildContentStreamRequest(contentItem, isMpegRequired)));
        }
      });
    },
    fetchContentDetails(contentItem, isMpegRequired) {
      console.log("Content item : ", contentItem);
      return new Promise((resolve, reject) => {
        if (contentItem.contentdetails) {
          resolve(true);
        } else {
          let requestData = {};
          let queryParams = {};
          this.localDisplayLang = this.localDisplayLang == 'ml' ? 'mal' : this.localDisplayLang;
          queryParams.displaylanguage = this.localDisplayLang;

          if (isMpegRequired == true) {
            queryParams.mpegtspackage = "YES";
          }

          requestData.contentid = contentItem.objectid;

          requestData.params = queryParams;

          resolve(store.dispatch("contentDetail", requestData));
        }
      });
    },
    handleFetchContentStreamUrl(response, contentItem) {
      contentItem.streamUrl = response.data.streamfilename;
      contentItem.scrubbing = response.data.packagedfilelist && response.data.packagedfilelist.scrubbing ? response.data.packagedfilelist.scrubbing : '';

    },
    getAvailability(contentItem) {
      if (contentItem.hasOwnProperty("contentdetails") && contentItem.contentdetails.length > 0) {
        contentItem.isContentDetail = true;
        let packageDetails = this.getFilteredPackage(contentItem);
        let availPlanList = this.getAvailabilityAndPlanDetails(packageDetails, this.availabilities);
        let filteredAvailabilityList = availPlanList.filteredAvailabilities;

        filteredAvailabilityList.every((availability) => {
          if (availability.pricemodel === "PLAN") {
            if (this.subscriberid && this.subscriptions.length > 0) {
              this.subscriptions.every((subscription) => {
                //check if next billing date is greater than today's date.
                if (this.getDateForPlayback(subscription.nextbilling) < this.getTodaysDate()) return;

                let value = subscription.availabilityset.includes(availability.availabilityid);

                if (value) {
                  contentItem.isDrmContent = true;
                  contentItem.videoType = "Content";
                  //creating this playback_details for content playback.
                  contentItem.playback_details = {
                    packageid: packageDetails.packageid,
                    availabilityid: availability.availabilityid,
                    drmscheme: packageDetails.drmscheme[0],
                  };

                  return false;
                }
              });
            } else if (this.subscriberid && this.subscriptions.length === 0) {
              console.log(
                "content price model is not free and user has to subscribe to the plan to watch this content"
              );
            }
          } else if (availability.pricemodel == "FREE") {
            contentItem.isDrmContent = true;
            contentItem.videoType = "Content";
            //creating this playback_details for content playback.
            contentItem.playback_details = {
              packageid: packageDetails.packageid,
              availabilityid: availability.availabilityid,
              drmscheme: packageDetails.drmscheme[0],
            };
            return false;
          }
        });
      } else if (!contentItem.hasOwnProperty("contentdetails")) {
        contentItem.isContentDetail = false;
      }
    },
    getAvailabilityAndPlanDetails(packageDetails, availabilitiesList) {
      let priceAndAvailabilityData = {};
      priceAndAvailabilityData.pricemodel = [];
      priceAndAvailabilityData.filteredAvailabilities = [];

      packageDetails.availabilityset.forEach((availability) => {
        let availabilityIndex = availabilitiesList.findIndex((element) => {
          return element.availabilityid === availability;
        });

        if (availabilityIndex > -1) {
          priceAndAvailabilityData.pricemodel.push(availabilitiesList[availabilityIndex].pricemodel);
          priceAndAvailabilityData.filteredAvailabilities.push(availabilitiesList[availabilityIndex]);
        }
      });

      return priceAndAvailabilityData;
    },
    getFilteredPackage(contentItem) {
      let temporaryVideoTag = document.createElement("video");
      let hasNativeHlsSupport = temporaryVideoTag.canPlayType("application/vnd.apple.mpegurl");

      let isAndroid = navigator.userAgent.includes("Android");

      let packageList = [];

      //pick content details if that availability id is there in availability list.
      this.availabilities.forEach((availability) => {
        contentItem.contentdetails.forEach((element) => {
          if (element.availabilityset.includes(availability.availabilityid)) {
            packageList.push(element);
          }
        });
      });

      let packageIndex = packageList.findIndex((contentPackage) => {
        if (!isAndroid && hasNativeHlsSupport != "") {
          return contentPackage.drmscheme[0] === "FAIRPLAY";
        } else {
          return contentPackage.drmscheme[0] === "WIDEVINE";
        }
      });

      if (packageIndex == -1) {
        return packageList[
          packageList.findIndex((contentPackage) => {
            return packageList.drmscheme[0] === "NONE";
          })
        ];
      } else if (packageIndex > -1) {
        return packageList[packageIndex];
      }
    },
    loadChosenIndexContent(episodeIndex, episodeList, totalCount, isFirstTime = true) {
      this.fetchContentDetails(episodeList[episodeIndex], this.isMpegRequired)
        .then((resp) => {
          if (typeof resp == "object" && !resp.data.errorcode) {
            episodeList[episodeIndex].title = resp.data.title;
            episodeList[episodeIndex].contentdetails = resp.data.contentdetails;
            episodeList[episodeIndex].seriesname = resp.data.seriesname;
          } else if (typeof resp == "boolean" && resp == true) {
            console.log("Content detail present");
          } else {
            let playbackError = true;

            if (typeof resp == "object") {
              playbackError = resp.data;
            }
            throw playbackError;
          }

          this.fetchContentStreamUrl(episodeList[episodeIndex], this.isMpegRequired).then((resp) => {
            let loadPlayer = false;

            if (typeof resp == "object" && !resp.data.errorcode) {
              this.handleFetchContentStreamUrl(resp, episodeList[episodeIndex]);
              loadPlayer = true;
            } else if (typeof resp == "boolean" && resp == true) {
              loadPlayer = true;
            }
            if (loadPlayer == true) {
              this.loadContentMetaData(episodeList[episodeIndex], null);

              if (typeof resp == "object") {
                if (isFirstTime == true) {
                  let playlist = this.buildPlayerPlaylist(episodeList, 0);
                  this.playerInstance.loadPlaylist(playlist, totalCount);
                } else {
                  let playlist = this.playerInstance.playlist;
                  let updatedContentItem = this.createPlayerPlaylistItem(episodeList[episodeIndex], 0);
                  playlist.updateItem(episodeIndex, updatedContentItem);
                }
              }

              this.playerInstance.loadContent(episodeIndex).then(() => {
                this.playerInstance.play().then(() => {
                  console.log("Playback successful");
                });
              });
            } else {
              let playbackError = true;

              if (typeof resp == "object") {
                playbackError = resp.data;
              }
              throw playbackError;
            }
          });
        })
        .catch((error) => {
          console.log("Content playback failed : ", error);
          this.hidePlayer();
        });
    },
    loadChosenContentData(chosenContentIndex) {
      this.playerInstance.unloadPlayer();
      let episodeIndex = chosenContentIndex;

      this.loadChosenIndexContent(episodeIndex, this.episodeList, this.totalEpisodeCount, false);
    },

    loadMoreContentData() {
      let episodeIndex = this.episodeList.length;
      console.log("Load more data");

      this.fetchEpisodeList(this.content.objectid, this.seasonNum).then((response) => {
        if (!response.data.errorcode) {
          let episodeList = response.data.data;
          this.episodeList = this.episodeList.concat(episodeList);

          let deltaPlaylist = this.buildPlayerPlaylist(this.episodeList, episodeIndex);

          this.playerInstance.appendPlaylist(deltaPlaylist, this.totalEpisodeCount);
        }
      });
    },

    loadNextContentData(endedContentIndex) {
      let episodeIndex = endedContentIndex + 1;
      console.log("Content playback ended : ", endedContentIndex);

      if (episodeIndex < this.episodeList.length) {
        this.loadChosenIndexContent(episodeIndex, this.episodeList, this.totalEpisodeCount, false);
      } else {
        this.hidePlayer();
      }
    },
    showPlayer() {
      this.playerInstance.blowUpPlayer();
      this.playerInstance.registerEvent("currentcontentended", this.loadNextContentData);
      this.playerInstance.registerEvent("loadchosencontent", this.loadChosenContentData);
      this.playerInstance.registerEvent("loadmorecontent", this.loadMoreContentData);
      this.playbackInitiated = true;
      eventBus.$emit("player-visibility", true);
    },
    playContent(content, contentIndex) {
      if (
        this.subscriberid &&
        this.episodeList[contentIndex].isContentDetail &&
        this.episodeList[contentIndex].hasOwnProperty("playback_details")
      ) {
        this.showPlayer();

        // let seriesContentNode = null;
        //
        // if (content.category !== 'MOVIE') {
        //     seriesContentNode = this.content;
        //     this.playlistIndex = contentIndex;
        // }
        if (content.category == "MOVIE") {
          this.episodeList = [content];

          this.loadChosenIndexContent(contentIndex, this.episodeList, this.totalEpisodeCount, true);
        } else {
          this.loadChosenIndexContent(contentIndex, this.episodeList, this.totalEpisodeCount, true);
        }
      } else if (
        this.subscriberid &&
        content.isContentDetail &&
        (this.subscriptions.length === 0 || this.subscriptions.length > 0)
      ) {
        console.log("inside subscriptions check");

        this.setRegActiveDeeplink(true);

        //show subscribe popup.
        let payload = { listType: "onlySubscribe" };
        localStorage.setItem("payment_source", "Content Details");

        eventBus.$emit("subscribePopup", payload);
      } else if (this.subscriberid && !content.isContentDetail) {
        //show snackbar component.
        eventBus.$emit("replace-content", content);
      } else {
        //open lookup popup.
        let payload = {
          state: true,
          formType: "lookup",
        };
        eventBus.$emit("authPopup", payload);
      }
    },
    buildPlayerPlaylist(episodeList, startIndex) {
      let playlist = [];
      for (var aIndex = startIndex; aIndex < episodeList.length; aIndex++) {
        let playlistItem = this.createPlayerPlaylistItem(episodeList[aIndex], 0);
        playlist.push(playlistItem);
      }

      return playlist;
    },
    loadContentMetaData(contentMetaData, seriesMetaData) {
      let metaData = {
        content: contentMetaData,
        seriesContent: seriesMetaData,
      };

      eventBus.$emit("load-player-content", metaData);
    },
    hidePlayer() {
      this.playerInstance.shrinkPlayer();
      eventBus.$emit("player-visibility", false);

      let payload = { content: null, seriesContent: null };
      eventBus.$emit("load-player-content", payload);

      this.playerInstance.unloadPlayer(true);
    },
    extractThumbnailPoster(contentItem) {
      let posterKey = contentItem.poster;
      let thumbNailUrl = null;

      if (posterKey && Array.isArray(posterKey) == true && posterKey.length > 0) {
        // let posterFileList = posterKey[0].filelist;

        let posterFileList = posterKey.find((posterKeyNode) => posterKeyNode.postertype == "LANDSCAPE").filelist;

        if (posterFileList && Array.isArray(posterFileList) == true && posterFileList.length > 0) {
          let thumbNailNode = posterFileList.find((posterNode) => posterNode.quality == "THUMBNAIL");

          if (thumbNailNode) {
            thumbNailUrl = thumbNailNode.filename;
          }
        }
      }

      return thumbNailUrl;
    },
    createPlayerPlaylistItem(content) {

      let playlistItem = new vLivePlaylistItem({
        contentId: content.objectid,
        contenttype: content.category,
        title: content.title,
        description: content.shortdescription,
        thumbnail: this.extractThumbnailPoster(content),
        source: content.streamUrl != undefined && content.streamUrl != "" ? [content.streamUrl] : [],
        licenseServer: this.appConfig.providerDetails.drmLicenseUrl,
        packageid: content.playback_details ? content.playback_details.packageid : "",
        providerid: this.appConfig.providerDetails.providerId,
        drmscheme: content.playback_details ? content.playback_details.drmscheme : "",
        availabilityid: content.playback_details ? content.playback_details.availabilityid : "",
        providerSession: localStorage.getItem("sessionToken"),
        skip: content.skip && Array.isArray(content.skip) && content.skip.length > 0 ? content.skip : "",
        isDrmContent: content.isDrmContent,
        skip: content.skip && Array.isArray(content.skip) && content.skip.length > 0 ? content.skip : "",
        position: 0,
        contentTags: content.tags,
        scrubbing: content.scrubbing ?content.scrubbing[0] : '',
        adversity: {
          advisory: content.advisory,
          pgrating: content.pgrating,
          defaultgenre: content.defaultgenre || content.genre
        }
      });

      return playlistItem;
    },
  },
  components: {
    categoryCard: () => import(/* webpackChunkName: "categoryCard" */ "@/components/Templates/categoryCard.vue"),
    Loading: () => import(/* webpackChunkName: "Loading" */ "@/components/Templates/Loading.vue"),
  },
  mixins: [Utility, FirebaseActions],
};
</script>

<style lang="scss" scoped>
@import "@/sass/_variables.scss";
@import "@/sass/_components.scss";
@import "./contentsListing.scss"
</style>
